
$fa-font-path:"../vendor/components-font-awesome/fonts";

@import "../vendor/bootstrap-sass/assets/stylesheets/bootstrap";
@import "../vendor/components-font-awesome/scss/font-awesome.scss";
@import "../fonts/Lato/latofonts.css";
@import "../fonts/Lato/latostyle.css";

/*---- Mixins 	----*/

@mixin hover-state {
    &:hover, &:active, &:focus {
        @content;
    }
}

@mixin hover-state-all {
    &:hover, &:active, &:focus, & {
        @content;
    }
}

/*---- Layout 	----*/

$white: #FFFFFF;

.hidden-weak {
	display: none;
}

.underline {
	text-decoration: underline;
}

.row.no-gutters {
	margin-right: 0;
  	margin-left: 0;

	> [class^="col-"], > [class*=" col-"] {

    	padding-right: 0;
    	padding-left: 0;

	}
}

body {
	font-size: 16px;
	font-family: 'LatoWebLight', sans-serif;
	height: 100%;
	color: #423d3d;
	font-weight: 300;
	height: 100%;

	h2 {
		font-size: 36px;
	}

	p {
		margin: 5px 0px;
	}
}

header {

	h1 {

		height: 100%;
		display: inline;
		font-size: 42px;

	}

	.header-block {

		width: 100%;

		&.upper {

			.language-icon {
				float: right;
				font-size: 22px;
				margin-right: 5px;
			}

			#google_translate_element {

				float: right;

				img {

					display: none;

				}

				span span:first-of-type {

					padding-right: 5px;
					//display: none;

				}

			}

		}

		&.middle {

			display: inline-block;
			position: relative;
			padding-bottom: 5px;

			.mobile-icons {

				display: inline-block;
				position: absolute;
				right: 0;
				top:50px;
				margin-right: 15px;
				margin-top: 5px;

				i {
					font-size: 28px;

					&:first-child {
						margin-right: 10px;
					}

				}

			}

			.map-choice {

				width: 100%;
				position: absolute;
				height: 97px;
				left: 0;
				top: 0;

				.choice {
					text-align: center;
					height: 100%;
					vertical-align: middle;
					width: 50%;
					display: inline-flex;
					align-items: center;

					a {
						width: 100%;
						height: 100%;
						display: block;
						vertical-align: middle;
						padding: 25px;
					}

					&.left {
						float: left;
					}

					&.right {
						float: right;
					}

				}

				.close-choice {
					position: absolute;
					top: 0px;
					right: 10px;
					font-size: 28px;
				}

			}

			.header-info {
				display: none;
				text-align: right;

				.map-info {
					text-decoration: none;
				}
			}

		}

		&.lower {

			.navbar {
				margin-bottom: 0;
				min-height: 38px;
				text-align: center;
				font-size: 16px;
				white-space: nowrap;
				border-radius: 0;
				border: none;

				i {
					display: none;
					padding-bottom: 5px;
					font-size: 22px;
					//font-size: 130%;
				}

			}

			.navbar-header {

				.navbar-toggle {

					float: none;
					width: 100%;
					margin: 0;
					border: none;

					.icon-bar {

						margin: 4px auto auto;

					}

				}

			}

			.navbar-collapse {
				border:none;
			}

		}

	}

}

.main-content-row {
	position:relative;
	margin-bottom: 10px;
	height: 100%;
}

.carousel {
	height: 300px;
	overflow: hidden;
}

video#bgvid{
	width: 100%;
	height: 100%;
	object-fit: cover;
	background-size: cover; 
}

.notification-banner {

	position: absolute;
	display: none;
	top: 25px;
	left: 25px;
	width: 60%;
	padding: 20px;

	h2 {
		margin: 0;
		margin-bottom: 10px;
	}

	.close-notification {
		position:absolute;
		top: 5px;
		right: 10px;
		font-size: 28px;
	}

}

.notification-block {

	padding: 10px 25px;
	margin-bottom: 10px;
	margin-top: 10px;

	h2 {
		margin: 0;
		margin-bottom: 10px;
	}

	.close-notification {
		position:absolute;
		top: 5px;
		right: 25px;
		font-size: 28px;
	}

}

#main-carousel {
	z-index: 1;
	height: 300px;

	.item {
		position: relative;

		img {
			height: 100%;
		}

		.item-label {
			position: absolute;
			bottom: 100px;
			//top: 50px
			//left: 0px;
			left: 150px;
			height: auto;
			z-index: 2;
			padding: 10px 10px;
			max-width: 330px;

			h3 {
				margin: 0;
				font-size: 28px;
			}

		}

	}

}

.owl-carousel {
	position: relative;
	height: 100%;
	
	div:not(.owl-controls, .owl-dots) {
		height: 100%;
	}
}

.owl-stage {
	width:100%;
}

.owl-dots {
	display: none;
}

.header-menu {
	width: calc(100% - 30px);
	position: absolute;
	z-index: 3;
	border: 1px solid;
	padding: 30px 20px 5px 30px;
	//height: 300px;
	text-align: center;
   	
	//top: -1000px;


	h2 {
		margin: 0px 0px 15px 0px;
	}

	h3 {
		margin: 0;
	}

	.header-menu-row {
		
		margin-bottom: 15px;
		position: relative;
		//border-bottom: solid 1px;
		text-align: left;

	}
	
	.icon-col {
		text-align: center;
		font-size: 24px;
	}

	.close-button {
		position:absolute;
		top: 0px;
		right: 5px;
		font-size: 36px;
	}

	.bottom-border {
		border-bottom: 1px solid;
  		position: absolute;
  		width: 95%;
  		margin: 0 auto;
  		left: 0;
  		right: 0;
  		bottom: 0;
	}

	&.type-1 {

		text-align: left;

		h2 {

			margin: 0px 0px 15px 1.25%;

		}

		.icon-col {
			display: none;
		}

		.header-menu-row {
			margin-bottom: 0;
			min-height: 54px;
		}

		.bottom-info {
			margin-top: 15px;
			text-align: center;
		}		

	}

	&.type-2 {

		text {
			display: block;
		}

		a {
			display: inline-block;
		}

		.col-md-4 {
			padding-top: 10px;
			padding-bottom: 10px;
		}

		.button-col {
			text-align: center;
		}

		.link-col {
			a {
				width: 100%;
				margin-bottom: 5px;
			}
		}

		.button {
			display: inline-block;
			padding: 10px;
			margin-bottom: 10px;
			width: 75%;
		}

	}

	&.type-3 {

		h3 {
			margin-bottom: 10px;
		}

		text {
			display: block;
		}

		a {
			margin-top: 5px;
			display: inline-block;
		}

		.active {
			display: inline-block;
		}

		.nav {
			margin-top: 10px;
			margin-bottom: 15px;
		}

		.left {
			text-align: left;
		}

		.right {
			text-align: right;
		}

	}

}

.carousel-menu {

	position:absolute;
	top: 0;
	margin: 10px 15px;
	z-index:2;
	width: calc(100% - 30px);
	display: flex;
  	flex-direction: column;
  	justify-content: center;
  	flex-wrap: shrink;

	.carousel-button {

		padding: 10px 10px;
		margin: 2px;
		text-align: center;

		h3 {
			font-size: 20px;
			margin: 0;
		}

	}

	a:hover, a:active {
		text-decoration: none;
	}

}

.block-area {
	//width:100%;
	//display: inline-block;
	margin-bottom: 10px;
}

.block {

	width:100%;
	display: inline-block;
	border: none;
    box-shadow: none;
    //max-height: 432px;
    margin-bottom: 15px;

	.panel-heading {

		padding:0;
		//border: 1px solid #dddddd;
		border: none;
		background-color: $white;
    	border-radius: 0;
    	width: auto;
    	text-align: center;

		img {
			max-width: 100%;
    		height: 220px;
		}
	}

	.panel-body {

		text-align: center;

		h2 {
			margin-top: 0px;
		}

	}

	.widget {
		padding: 15px 0px; 
		max-height: 200px;
	}

	.widget-full {

		height: 100%;
		overflow-x: hidden;

		iframe {
			height: 100%;
		}

		.fb-page span {
			width:100% !important;
			display: inline-block;
		}

	}

	.bth-widget {
		//overflow-y: scroll;
	}

	#nhs-choices-dementia-widget {

		h1 {
			display: none;
		}

		img {
			left: 0;
			right: auto;
		}

		width: 100%;
		height: auto;
		//overflow-y: scroll;

	}

	#NHS-Choices-Comments-Widget {
		height: 100%;
	}

	#NHS-Choices-Comments-Widget header .logo {
		float: none;
	}

}

.nhs-serv {
	border: 2px solid #0069b6 !important;
}

.staff-block {

	width:100%;
	display: inline-block;
	border: none;
    box-shadow: none;
    margin-bottom: 0px;
    //max-height: 300px;

    .panel-heading {

		padding:0;
		border: none;
		background-color: $white;
    	border-radius: 0;
    	text-align: center;

		img {
			//width: 100%;
    		height: 220px;
		}
	}

	.panel-body {

		text-align: center;

	}

}

footer {

	.footer-upper {

		padding: 20px;
		border-radius: 0px;

		h4 {
			font-size: 16px;
			display: inline-block;
			margin: 0 15px 0 0;
		}

		.footer-left {
			float: left;
		}

		.footer-right {
			float: right;
		}

	}

}

.info-page {

	padding: 10px;

	h2 {
		margin: 20px 10px 40px 10px;
	}

	h3 {
		margin-top: 40px;
		font-size: 24px;
	}

	img {
		max-height: 250px;
	}

	.image-block {
		text-align: center;
		display: block;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	button {
		min-width: 100px;
		min-height: 35px;
		margin: 15px
	}

	.accordion-group {

		margin: 20px 0;

		.panel {

			border-radius: 0px;
			margin-bottom: 5px;

			.panel-heading {

				border-radius: 0px;
				h4 {
					font-size: 18px;
				}

			}

			.panel-body {
				border-radius: 0px;
			}

		}

	}

}

.staff-page {

	padding: 10px;

	h2 {
		margin: 20px 10px 40px 10px;
	}

	h3 {
		margin-top: 0px;
		margin-bottom: 10px;
		font-size: 24px;
	}

	img {
		max-height: 250px;
	}

	.image-block {
		text-align: center;
		display: block;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	button {
		min-width: 100px;
		min-height: 35px;
		margin: 15px
	}

	.staff-member {

		height: 275px;
		margin-bottom: 15px;

	}

}

.placeholder {

	background-image: url('http://via.placeholder.com/350x150');
	
}

.goog-te-menu-frame {
	max-width:100% !important;
	margin: 0 5px;
	width:calc(100% - 10px) !important;
	left: 0 !important;
}

@media (min-width:$screen-xs-min) {

	#bgvid {
		//height: auto;
	}

	.carousel {
		width: 100%;
	}

}

//480px & greater

@media (min-width:$screen-sm-min) {

	header {

		margin-bottom: 10px;

		.header-block {

			&.middle {

				$bottom-pad: 10px;

				padding-bottom: $bottom-pad;

				.mobile-icons {
					bottom: $bottom-pad;
					top:auto;
				}

				.header-break {
					display: none;
				}

			}

			&.lower {

				.navbar-collapse {
					padding: 0;
				}

				.navbar-nav {
					padding-top: 5px;
				}

				ul {
					width: 100%;
					display: flex;
					flex-direction: row;
					justify-content: space-around;

					li {
						font-size: 11px;
						//display: inline-block;
				        float: none;
				        flex-grow: 1;
				        text-align: center;
				        //width: 8%;

				        a {
				        	padding: 10px 5px;
				        }

					}

				}

				.navbar i {
					font-size: 18px;
				}

				.navbar {

					i {
						display: block;
					}

				}

			}

		}
	}

	.main-container {
		min-height: 60vh;
	}

	.main-content-row {
		min-height: 400px;
	}

	.row-container {
		grid-auto-rows: 1fr;
	}

	.header-menu {
		height: 400px;
		text-align: left;

		h2 {
			text-align: left;
		}

		.header-menu-row {

			display: flex;
			align-items: center;

		}
	}

	.notification-banner {
		max-width: 40%;
	}

	.block {
		//min-height: 432px;
		height: 100%;
	}

	.block-area {
		.col-md-3 {
			//padding-left: 0;
			//padding-right: 0;
		}
		display: flex;
		flex-direction: row;
	}

	#bgvid {
		height: 300px;
	}

	.bth-widget {
		height: inherit;
	}

	#main-carousel {
		height: 300px;

		.item {

			.item-label {
				bottom: 250px;
				left: 0px;
			}

		}

	}

	.carousel-menu {

		.carousel-button {
			margin: 2px;
		}

	}

	.owl-dots .owl-dot span{

	}

	/*
	.carousel-menu {
		width: auto;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: column;

		.carousel-button {

			&:first-of-type {
				align-self: flex-start;
			}

			&:last-of-type {
				align-self: flex-end;
			}

		}

	}
	*/

	.header-menu {

		height: 400px;

		&.type-1 {

			text-align: center;

			.icon-col {
				display: inline-block;
			}

			.bottom-info {
				margin-top: 0px;

				h4 {
					margin: 0px;
				}

			}

		}

	}

	.carousel-menu {

		width: auto;
		right: 25px;
		top:0;
		bottom:0;

		.carousel-button {
			margin: 5px;
		}

	}

}

//768px & greater

@media (min-width:$screen-md-min) {

	header {

		h1 {
			display: block;
		}

		.header-block {

			&.middle {

				.mobile-icons {
					display: none;
				}

				.header-info {
					display: block;
				}

			}

			&.lower {

				.navbar-collapse {
					padding: 0;
				}

				ul {

					li {
				        font-size: 16px;
					}

				}

				.navbar i {
					font-size: 22px;
				}

			}

		}

	}

	.block {
		margin-bottom: 0px;
	}

	.carousel {
		height: 400px;
	}

	#main-carousel {
		height: 400px;

		.item {

			.item-label {
				bottom: 150px;
				left: 100px;
			}

		}

	}

	.carousel-menu {

		.carousel-button {
			margin: 10px;
		}

	}

	.goog-te-menu-frame {
		//width:980px !important;
		left: 0 !important;
		right: 0 !important;
		margin: auto;
		width: 750px !important; 
	}

	#\:1\.menuBody {
		width: 100% !important;
	}

}

//992px & greater

@media (min-width:$screen-lg-min) {
	.goog-te-menu-frame {
		//width:980px !important;

		width: 1025px !important; 
	}
}

//1200px & greater

/*---- Varables	----*/

$nhs-blue: #005EB8;
$white: #FFFFFF;
$black: #000000;
$grey: #d3d3d3;
$white: #FFFFFF;

/*---- Colors 	----*/


header {

	h1 {
		color: $nhs-blue;
	}

	.language-icon {
		color: $nhs-blue;
	}

	.map-info {
		color: $black;
	}

	.map-choice {
		background-color: $nhs-blue;
		border-bottom: 1px solid $white;

		 .left {
			border-right: 1px solid $white;
		}

		a {
			color: $white;
		}

		i {
			color: $white;
		}

	}

}

.navbar-default {
	background-color: $nhs-blue;

	.navbar-nav > li {

		&, & > a {

			@include hover-state-all {
				color: $white;
			}
		}
	}

	.navbar-toggle {

		@include hover-state-all {
			background-color:$nhs-blue;
		}

		.icon-bar {
			background-color: $white;
		}
	}
}

.header-menu {
	background-color: $white;
	border-color: $nhs-blue;

	h2, h3 {
		color: $nhs-blue;
	}

	.bottom-border {
		border-color: $nhs-blue;
		opacity: 0.3;
	}

	.close-button {
		color: $nhs-blue;
	}

	&.type-1 {

		h4 {
			color: $nhs-blue;
		}

		.row-bottom {
			//background-color: $nhs-blue;

			.bottom-info-box {

				h4 {
					//color: $white;
				}
			}
		}



	}

	&.type-2 {

		.button {
			background-color: $nhs-blue;
			color: $white;
		}

	}

	&.type-3 {

		.nav-tabs {

			a {

				@include hover-state-all {
				background-color: transparent;
				border-color: transparent;
				color: $nhs-blue;
				}
			}

			color: $nhs-blue;
			border-bottom-color: $nhs-blue;

			li.active a {
				color: $nhs-blue;
				border-color: $nhs-blue;
				border-bottom-color: $white;
			}

		}

	}

}

.carousel-button, .item-label {
	background-color: #000000;
	background: rgba(0, 0, 0, 0.80);

	h3 {
		color: $white;
	}

}

.notification-banner, .notification-block {
	background: rgba(0, 94, 184, 1);
	color: $white;
}

.block {

	h2 {
		color: $nhs-blue;
	}

}

footer {

	.footer-upper {
		background-color:$nhs-blue;
		color: $white;

		h4 {
			color: $white;

			@include hover-state {
				color: $white;
				text-decoration: underline;
			}

		}

	}

	.footer-lower {
		margin-top: 10px;
		text-align: right;
	}

}

.info-page {

	h2, h3 {
		color: $nhs-blue;
	}

	.accordion-group {

		.panel {

			border: 1px solid $nhs-blue;

			.panel-heading {
				background-color: $nhs-blue;
				border-color: $nhs-blue;

				h4 {
					color: $white;
				}

			}

		}

	}

}	


@media (min-width:$screen-xs-min) {
}

//480px & greater

@media (min-width:$screen-sm-min) {
}

//768px & greater

@media (min-width:$screen-md-min) {
}

//992px & greater

@media (min-width:$screen-lg-min) {
}
